// extracted by mini-css-extract-plugin
export var blogContainer = "blogPagination-module--blogContainer--X7l7c";
export var box = "blogPagination-module--box--4VkZg";
export var category = "blogPagination-module--category--PjvLN";
export var content = "blogPagination-module--content--GZpEr";
export var cover = "blogPagination-module--cover--Wg-yC";
export var date = "blogPagination-module--date--+3ktk";
export var description = "blogPagination-module--description--s7ejK";
export var menu = "blogPagination-module--menu--In0JY";
export var posts = "blogPagination-module--posts--d8Y2A";
export var small = "blogPagination-module--small--+k5So";
export var tag = "blogPagination-module--tag--cHdN-";
export var text = "blogPagination-module--text--oy4Zl";
export var title = "blogPagination-module--title--HgJrb";